@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import './../base/variables';
@import './../helpers/mixins';


.block-partners-section-wrap {
  padding: 48px 0;
  @include media-breakpoint-up(lg) { 
    padding: 64px 0;
  }
} 

.partners-swiper {
  position: relative;
  .swiper-slide {
    width: auto;
    height: auto;
    .partner-item {
      display: block;
      background: $white;
      border-radius: 8px;
      color: $secondary;
      font-weight: 500;
      text-decoration: none;
      width: 168px;
      text-align: center;
      height: 100%;
      .image-wrap {
        text-align: center;
      }
      @include media-breakpoint-up(lg) { 
        width: auto;
      }
    }
  }
  
}

.partners-slider-wrap {
  position: relative;
  .swiper-button-prev, .swiper-button-next {
    display: none;
    color: $secondary;
    //font-size: toRem(10);
    width: 40px;
    height: 40px;
    border: 1px solid $gray-200;
    border-radius: 8px;
    margin: 0;
    top: calc(0px - 37px);
    transform: translateY(-50%);
    transition: $transition-base;
    &:after {
      font-size: toRem(12);
      font-weight: bold;
    }
    &.swiper-button-disabled {
      //visibility: hidden;
    }
    
  }
  .swiper-button-prev {
    left: -15px;
    border-radius: 0px 8px 8px 0px;
    
  }
  .swiper-button-next  {
    right: -15px;
    border-radius: 8px 0 0 8px;
  }
  @include media-breakpoint-up(sm) { 
    .swiper-button-prev, .swiper-button-next {
      border-radius: 8px;
    }
  }
  /* @include media-breakpoint-up(md) { 
    .swiper-button-prev, .swiper-button-next {
      top: 50%;
    }
    .swiper-button-prev {
      left: -20px;
    }
    .swiper-button-next  {
      right: -20px;
    }
  } */
  @include media-breakpoint-up(lg) { 
    .swiper-button-prev, .swiper-button-next {
      top: 50%;
      display: flex;
      &:hover {
        border-color: $primary;
        color: $primary;
      }
    }
  }
  @include media-breakpoint-up(xl) { 
    
    .swiper-button-prev {
      left: -30px;
    }
    .swiper-button-next  {
      right: -30px;
    }
  }
  @include media-breakpoint-up(xxl) { 
    
    .swiper-button-prev {
      left: -55px;
    }
    .swiper-button-next  {
      right: -55px;
    }
  }
}