// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// scss-docs-start gray-color-variables
$white:    #ffffff;
$gray-100: #F9FAFB; //
$gray-200: #DADCDF; 
$gray-300: #C8C8C8; 
$gray-400: #ced4da;
$gray-500: #93979C; //
$gray-600: #6A6E71; //
$gray-700: #5D6063; //
$gray-800: #3D3E40; //
$gray-900: #2B2D2E; 
$gray-1000: #111111;   
$black:    #000;

$white-light: #FCFDFD;
$white-normal: #F9FAFB;
$white-normal-active: #EBEEF2;

$text: $black;


$blue:    #219EBC;
$blue-light: #339DFF;
$indigo:  #6610f2;
$purple:  #c9b0d2;
$pink:    #FD8B7D;
$pink-dark: #FF385C;
$red-100: #FEEAEA;
$red:     #EE2A2A;
$red-light:     #EB5757;
$orange:  #FF8E26;
$yellow:  #e2b93b;
$green:   #44AA00;
$turquose:   #1D758D;
$teal:    #20c997;
$cyan:    #17a2b8;
$brown: #55392A;
$gray : $gray-800;


$green-50: #EDF8EA;
$green-100: #cfe7c4;
$green-500: #86C556;
$green-600: $green;
$green-700: #477F2E;
$green-800: #376224;
$green-900: #004B1F;


$warning-light : #fcf8eb;
$warning-normal-active: #B5942F;
$warning-normal-hover: #CBA735;
$warning-dark-hover: #886F23;

$info-light: #EAF2FD;
$info-normal: #2F80ED;

$surface-normal-active: #EBEEF2;
$surface-normal: #F9FAFB;

$surface-light: #FCFDFD;

$primary-hover : $green;

$primary:       $black;
$primary-light: #ECF7E6;
$primary-dark : #307900;
$secondary:     $green;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// stylelint-disable
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
$sizes: map-merge((
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%
), $sizes);
// stylelint-enable

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $white;
$body-color:                $black;


$link-color:                              $secondary;
$link-hover-color:                        $green-700;
// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);



// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12;
$grid-gutter-width:           30px;

// Components
//
// Define common padding and border radius sizes and more.


$transition-base:             all .3s ease-in-out;
$transition-fade:             opacity .15s linear;
$transition-collapse:         height .35s ease;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      Roboto, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:            $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-lg:                ($font-size-base * 1.125);
$font-size-sm:                ($font-size-base * .875);

$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;

$font-weight-base:            $font-weight-normal;
$line-height-base:            1.375;

$h1-font-size:                $font-size-base * 2.5;
$h2-font-size:                $font-size-base * 3;
$h3-font-size:                $font-size-base * 1.75;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;

$headings-margin-bottom:      calc($spacer / 2);
$headings-font-family:        inherit;
$headings-font-weight:        500;
$headings-line-height:        1.2;
$headings-color:              inherit;


$lead-font-size:              ($font-size-base * 1.25);
$lead-font-weight:            300;

$small-font-size:             80%;

$text-muted:                  $gray-600;

$blockquote-font-size:        ($font-size-base * 1.25);

$mark-padding:                .2em;

$dt-font-weight:              $font-weight-bold;

$kbd-box-shadow:              inset 0 -.1rem 0 rgba($black, .25);
$nested-kbd-font-weight:      $font-weight-bold;

$list-inline-padding:         .5rem;

$mark-bg:                     #fcf8e3;

$hr-margin-y:                 $spacer;

$enable-smooth-scroll:        false;


// Navs

// scss-docs-start nav-variables
$nav-link-padding-y:                .5rem ;
$nav-link-padding-x:                1rem ;
$nav-link-font-size:                null ;
$nav-link-font-weight:              null ;
$nav-link-transition:               color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out ;
$nav-link-disabled-color:           $gray-600 ;

$nav-tabs-border-color:             $gray-300 ;
$nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color ;
$nav-tabs-link-active-color:        $gray-700 ;
$nav-tabs-link-active-bg:           $body-bg ;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg ;

// scss-docs-end nav-variables


// Navbar

// scss-docs-start navbar-variables
$navbar-padding-y:                  $spacer * .5 ;
$navbar-padding-x:                  null ;

$navbar-nav-link-padding-x:         0 ;

$navbar-brand-font-size:            $font-size-lg ;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2 ;
$navbar-brand-height:               $navbar-brand-font-size * $line-height-base ;
$navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) * .5 ;
$navbar-brand-margin-end:           1rem ;

$navbar-toggler-padding-y:          .25rem ;
$navbar-toggler-padding-x:          .75rem ;
$navbar-toggler-font-size:          $font-size-lg ;
$navbar-toggler-transition:         box-shadow .15s ease-in-out ;

$navbar-light-color:                rgba($black, .55) ;
$navbar-light-hover-color:          rgba($black, .7) ;
$navbar-light-active-color:         rgba($black, .9) ;
$navbar-light-disabled-color:       rgba($black, .3) ;
$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") ;
$navbar-light-toggler-border-color: rgba($black, .1) ;
$navbar-light-brand-color:          $navbar-light-active-color ;
$navbar-light-brand-hover-color:    $navbar-light-active-color ;
// scss-docs-end navbar-variables

// scss-docs-start navbar-dark-variables
$navbar-dark-color:                 rgba($white, .55) ;
$navbar-dark-hover-color:           rgba($white, .75) ;
$navbar-dark-active-color:          $white ;
$navbar-dark-disabled-color:        rgba($white, .25) ;
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") ;
$navbar-dark-toggler-border-color:  rgba($white, .1) ;
$navbar-dark-brand-color:           $navbar-dark-active-color ;
$navbar-dark-brand-hover-color:     $navbar-dark-active-color ;
// scss-docs-end navbar-dark-variables


$form-label-margin-bottom: 8px;
$form-label-font-weight: 500;

$input-btn-padding-y:         .5625rem;
$input-btn-padding-x:         1.125rem;
$input-btn-padding-y-sm:      .25rem;
$input-btn-padding-x-sm:      .5rem;
$input-btn-font-size-sm:      $font-size-sm;

$input-btn-padding-y-lg:      .5rem;
$input-btn-padding-x-lg:      1rem;
$input-btn-font-size-lg:      $font-size-lg;


$input-padding-y:                       $input-btn-padding-y;
$input-padding-x:                       $input-btn-padding-x;
//$input-font-size:                       $input-btn-font-size;
$input-font-weight:                     $font-weight-base;
//$input-line-height:                     $input-btn-line-height;

$input-bg:                              $white;
$input-disabled-color:                  null;
$input-disabled-bg:                     $gray-200;
$input-disabled-border-color:           null;

$input-color:                           #646464;
$input-border-color:                    $gray-200;

$enable-shadows: false;
$input-focus-bg:                        $input-bg;
$input-focus-border-color:              $primary;
$input-focus-color:                     $input-color;
//$input-focus-width:                     $input-btn-focus-width;
$input-focus-box-shadow:                none;

$input-placeholder-color:               $gray-600;
$input-plaintext-color:                 $body-color;

$form-select-focus-box-shadow : none;
$form-check-input-focus-box-shadow: none;


$form-label-color: #646464;
$input-border-radius: 10px;

